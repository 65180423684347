import {
  arraysShareCommonElements,
  getJsonWithErrorHandling,
  isDateAfter,
  isDateOlderThan,
  variantToBool
} from './helper'
import {
  type Answer,
  type Conditional,
  type EversignTemplate,
  type Question,
  type Snack
} from '../dictionaries/commonInterfaces'
import { type ObjectId } from 'mongodb'
import { states } from '@src/dictionaries/dictionaries'
import { apiVercelFallback, apiVercelFallbackRaw } from '@src/helpers/clientSide/fetch'
import { QUESTION_TYPE } from '@src/dictionaries/commonEnums'
import base64url from 'base64url'

const thisFile = 'newHelper.ts'

/**
 * Filters out empty values from an object.
 * @param obj - The object to filter.
 * @returns The filtered object.
 */
export const filterEmptyValues = <T extends object>(obj: T): T => {
  const newObj = {} as T
  Object.keys(obj).forEach((key) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (obj[key] !== null && obj[key] !== undefined) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newObj[key] = obj[key]
    }
  })
  return newObj
}

/**
 * Converts a string or Date object to a Date object.
 * If the input value is null or an empty string, it returns null.
 * If the input value is already a Date object, it returns the same object.
 * Otherwise, it creates a new Date object from the input value and returns it.
 *
 * @param value - The value to be converted to a Date object.
 * @returns The converted Date object or null if the input value is null or an empty string.
 */
export const returnValueAsDate = (value: Date | null | number | string | undefined): Date | null => {
  if (!value || value === null || value === '') {
    return null
  } else if (value instanceof Date) {
    return value
  } else {
    return new Date(value)
  }
}

export interface ConditionalCheckerReturn {
  conditionalsMet: boolean
  unmetConditionals: Array<{ question?: Question } & Conditional>
}

export interface ConditionalResourceValue {
  fieldType?: string
  fieldValue?: Array<Record<string, Answer>> | boolean | Date | number | string | string[] | undefined
  question?: ObjectId | string
  questionSlug?: string
  slug: string
}

/**
 * Checks conditions for a resource to see if they are met.
 * @param resource - The resource to check conditions for.
 * @param values - The values to check against.
 * @param flagIds - The flag ids to check against.
 * @returns object, { met:boolean, unmetConditions: string[] }
 */
export const conditionalResourceChecker = (
  resource: EversignTemplate | Question,
  fieldValues: ConditionalResourceValue[],
  flagIds: string[] = []
): ConditionalCheckerReturn => {
  try {
    if (!resource?.conditionals?.length) {
      // Question has no conditionals, question is visible
      return { conditionalsMet: true, unmetConditionals: [] }
    }

    // Assume conditionals are met, change to false if any conditionals are not met
    const result: ConditionalCheckerReturn = { conditionalsMet: true, unmetConditionals: [] }

    resource.conditionals.forEach((conditional: { question?: Question } & Conditional) => {
      const fieldValueToCheck = fieldValues?.find((value: ConditionalResourceValue) => {
        // Check if the value is the one we are looking for if is not a repeating question in the eversign template
        // If the value is a repeating question AND is a Eversign Template (so it doesn't have a questionSlug), we need to check all subAnswers separately
        if (value?.fieldType === QUESTION_TYPE.REPEATING_QUESTION && !('questionSlug' in resource)) {
          const subAnswersArray = value?.fieldValue as Array<Record<string, Answer>>

          // Get all answers that are relevant to the conditional inside the repeating question subAnswer array
          const relevantAnswers = subAnswersArray
            .map((subAnswerObject) => {
              const subAnswerQuestion =
                subAnswerObject?.[conditional?.questionSlug ?? conditional?.question?.questionSlug ?? '']
              if (subAnswerQuestion?.question && subAnswerQuestion?.question === conditional?.question?._id) {
                return subAnswerQuestion
              }
              return null
            })
            .flat()
            .filter((value) => !!value)

          // Check if any of the relevant answers match the conditional
          relevantAnswers.forEach((relevantAnswer) => {
            if (!relevantAnswer) return

            validateConditionalAnswer(result, flagIds, conditional, {
              slug: conditional?.questionSlug ?? conditional?.question?.questionSlug ?? '',
              fieldValue: relevantAnswer?.answer,
              fieldType: conditional?.question?.answerType
            })
          })

          // Return false to force not found so we can use last saved result status
          return false
        }

        // If it's not, do the check as always
        return (
          (value?.question && value?.question === (conditional?.questionId ?? conditional?.question?._id)) ||
          (value?.slug && (value?.slug === conditional?.questionSlug || value?.slug === conditional?.slug)) ||
          (value?.questionSlug &&
            (value?.questionSlug === conditional?.questionSlug || value?.questionSlug === conditional?.slug))
        )
      })

      // If we still haven't found a conditionalMet status of false, check the current conditional, if not, skip
      if (result.conditionalsMet) {
        validateConditionalAnswer(result, flagIds, conditional, fieldValueToCheck)
      }
    })

    return result
  } catch (error) {
    console.error(thisFile, 'conditionalResourceChecker error: ', (error as Error).message)
    return { conditionalsMet: true, unmetConditionals: [] } // If error, assume conditionals are met and return true
  }
}

/**
 * Validates a conditional answer against a set of conditions. Mutates the original result object and doesn't return anything.
 * @param result - The result object to store the validation outcome.
 * @param flagIds - An array of flag IDs.
 * @param conditional - The conditional object containing the conditions to check.
 * @param fieldValueToCheck - The value to check against the conditions.
 */
const validateConditionalAnswer = (
  result: ConditionalCheckerReturn,
  flagIds: string[],
  conditional: Conditional,
  fieldValueToCheck: ConditionalResourceValue | undefined
): void => {
  const fieldDelimiter = ' | '

  // Get the value to check against
  const jsonValue = getJsonWithErrorHandling(conditional?.answerValue || conditional?.fieldValue, {
    throwError: false
  })
  const fieldValue: Conditional['answerValue'] | Conditional['fieldValue'] =
    typeof jsonValue !== 'object' ? jsonValue : Array.isArray(jsonValue) ? jsonValue : jsonValue?.text

  // Check if the answer is a repeating question
  switch (conditional.operator) {
    case 'contains':
      // Only used for selects
      if (
        typeof fieldValue === 'string' &&
        !fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter)?.includes(fieldValue)
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      } else if (
        !arraysShareCommonElements(
          fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter),
          fieldValue as string[]
        )
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'doesNotContain':
      // Only used for selects
      // fieldValue will be string
      if (
        typeof fieldValue === 'string' &&
        fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter)?.includes(fieldValue)
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'isAnyOf':
      // Only used for selects
      // fieldValue will be array of strings
      if (
        !arraysShareCommonElements(
          fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter),
          fieldValue as string[]
        )
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'isNotAnyOf':
      // Only used for selects
      // fieldValue will be array of strings
      if (
        arraysShareCommonElements(
          fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter),
          fieldValue as string[]
        )
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'is':
      // if conditional is of type flag then we will check the condition based on the selected flag
      if (conditional?.type === 'Flag') {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        if (JSON.stringify(flagIds.includes(conditional?.flagId?.toString())) !== fieldValue.toString()) {
          result.conditionalsMet = false
          result.unmetConditionals.push(conditional)
        }
      } else if (
        fieldValueToCheck?.fieldType === QUESTION_TYPE.SELECT_MANY ||
        fieldValueToCheck?.fieldType === QUESTION_TYPE.SELECT_ONE
      ) {
        // If questionType is selectMany, answerToCheck.answer will be a string that needs to be split to an array.
        if (
          !(fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter)?.toString() === [fieldValue].toString())
        ) {
          result.conditionalsMet = false
          result.unmetConditionals.push(conditional)
        }
      } else if (
        fieldValueToCheck?.fieldType === QUESTION_TYPE.TEXT &&
        (fieldValueToCheck?.fieldValue as string)?.toLowerCase() !== (fieldValue as string)?.toLowerCase()
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      } else if (
        fieldValueToCheck?.fieldType === QUESTION_TYPE.NUMBER &&
        Number(fieldValue) !== Number(fieldValueToCheck?.fieldValue)
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      } else if (fieldValueToCheck?.fieldType === QUESTION_TYPE.BOOL) {
        if (variantToBool(fieldValue) !== variantToBool(fieldValueToCheck?.fieldValue)) {
          result.conditionalsMet = false
          result.unmetConditionals.push(conditional)
        }
      } else if (fieldValue && fieldValue !== fieldValueToCheck?.fieldValue) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'isNot':
      // if conditional is of type flag then we will check the condition based on the selected flag
      if (
        conditional?.type === 'Flag' &&
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        JSON.stringify(flagIds.includes(conditional?.flagId?.toString())) === fieldValue
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      } else if (fieldValueToCheck?.fieldType === QUESTION_TYPE.SELECT_MANY) {
        // If questionType is selectMany, answerToCheck.answer will be a string that needs to be split to an array.
        if (fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter)?.toString() === [fieldValue].toString()) {
          result.conditionalsMet = false
          result.unmetConditionals.push(conditional)
        }
      } else if (fieldValueToCheck?.fieldValue === fieldValue) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'isEmpty':
      if (
        fieldValueToCheck?.fieldValue &&
        (fieldValueToCheck?.fieldValue !== '' || fieldValueToCheck?.fieldValue?.toString() !== 'null')
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'isNotEmpty':
      if (
        !fieldValueToCheck?.fieldValue ||
        fieldValueToCheck?.fieldValue === '' ||
        fieldValueToCheck?.fieldValue === 'null'
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'greaterThan':
      if (fieldValueToCheck?.fieldValue && Number(fieldValueToCheck?.fieldValue) <= Number(fieldValue)) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'lessThan':
      if (fieldValueToCheck?.fieldValue && Number(fieldValueToCheck?.fieldValue) >= Number(fieldValue)) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'greaterThanOrEqualTo':
      if (fieldValueToCheck?.fieldValue && Number(fieldValueToCheck?.fieldValue) < Number(fieldValue)) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'lessThanOrEqualTo':
      if (fieldValueToCheck?.fieldValue && Number(fieldValueToCheck?.fieldValue) > Number(fieldValue)) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'isBefore':
      if (
        fieldValueToCheck?.fieldValue &&
        isDateAfter(fieldValueToCheck?.fieldValue?.toString(), fieldValue as string)
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'isAfter':
      if (
        fieldValueToCheck?.fieldValue &&
        isDateAfter(fieldValue as string, fieldValueToCheck?.fieldValue?.toString())
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'isOlderThan':
      if (
        fieldValueToCheck?.fieldValue &&
        !isDateOlderThan(
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          fieldValue[0] as number,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          fieldValue[1] ?? 'days',
          fieldValueToCheck?.fieldValue?.toString()
        )
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    case 'isYoungerThan':
      if (
        fieldValueToCheck?.fieldValue &&
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        isDateOlderThan(fieldValue[1] as number, fieldValue[0], fieldValueToCheck?.fieldValue?.toString())
      ) {
        result.conditionalsMet = false
        result.unmetConditionals.push(conditional)
      }
      break
    default:
      console.error(thisFile, 'validateConditionalAnswer error: ', 'operator not found')
      break
  }
}

export function saveQueuedCall<T>(value: T) {
  window?.localStorage?.setItem?.('queuedCall', JSON.stringify(value))
}

export function loadQueuedCall<T>(): T {
  return JSON.parse(window?.localStorage?.getItem?.('queuedCall') || 'null')
}

/**
 * Gets the abbreviation for a given state
 * @param stateToCheck {string} the state name to check (can be string such as `Alabama` or abbreviation `AL`)
 * @returns abbreviation {string} if `stateToCheck` found will return the two letter abbreviation, else will return the passed in `stateToCheck`
 */
export const getAbbreviationFromState = (stateToCheck: string): string => {
  const foundState = states.find(
    (state) =>
      state.name.toLowerCase() === stateToCheck.toLowerCase() ||
      state.abbreviation.toLowerCase() === stateToCheck.toLowerCase()
  )
  return foundState?.abbreviation || stateToCheck
}

/**
 * This function check to see if the flag is used on any Event Actions before being able to be deleted.
 * @param flag - The `flag` to be deleted.
 * @returns Returns an array, the array is empty if there are no conflicts
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'flag' implicitly has an 'any' type.
export const removeFlagsFromOpportunitiesEACheck = async (flag) => {
  const eventActionCheckResponse = await fetch(`/api/rest/eventActionCheck?flagToRemove=${flag}`, {
    method: 'GET'
  })
  if (!eventActionCheckResponse?.ok) {
    const EACheckArray = await eventActionCheckResponse.json()
    return EACheckArray.EAConflictArray
  }
  return true
}

/**
 * This function removes a filter from saved filters and checks for any conflicts with event actions or dashboards.
 * @param filter - The `filter` to be deleted
 * @returns If the 'checkResponse', or everything was successful, it will return an empty array
 * If there are conflicts, it will return an array with the conflicts.
 */
export const removeFiltersFromSavedFiltersEACheck = async (filter: string): Promise<string[]> => {
  const checkResponse = await fetch(`/api/rest/savedFilters?filterToRemove=${filter}`, {
    method: 'DELETE'
  })
  if (!checkResponse?.ok) {
    const conflictArray = await checkResponse.json()
    if (!conflictArray.conflictArray || !Array.isArray(conflictArray.conflictArray)) {
      return []
    }
    return conflictArray.conflictArray
  }
  return []
}

/**
 * This function handles downloading a given document.
 *
 * @param path - The path to the document to be downloaded
 * @param setSnackbar - (Optional) This is used to show a snackbar with an error if the fetch fails
 */
export const handleDownloadDoc = async (path: string, setSnackbar = (param: Snack) => {}) => {
  if (!path) return
  const base64Id = base64url(path)
  const getFile = await apiVercelFallback(`/rest/files/${base64Id}?provider=gcs`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (!getFile?.file) {
    setSnackbar({ visible: true, severity: 'error', message: 'Error downloading document' })
  } else {
    window.open(getFile.file, '_blank')
  }
}

/**
 * This function handles deleting a given document.
 *
 * @param path - The path to the document to be deleted
 * @param collection - The collection the document is in
 * @param collectionIdentifier - The identifier for the collection (e.g. `displayId | slug`)
 * @param docContext - (Optional) The field where the document is stored in the collection, defaults to `otherDocuments`
 * @param setSnackbar - (Optional) This is used to show a snackbar with an error if the request fails
 */
export const handleDeleteDoc = async (
  path: string,
  collection: string,
  collectionIdentifier: string,
  docContext?: string,
  setSnackbar = (param: Snack) => {}
) => {
  if (!path) return
  const base64Id = base64url(path)
  const response = await apiVercelFallbackRaw(
    `/rest/files/${base64Id}?provider=gcs&collection=${collection}&collectionIdentifier=${collectionIdentifier}&docContext=${docContext}`,
    {
      method: 'DELETE'
    }
  )

  if (!response?.ok) {
    const error = await response?.text()
    console.error('Error deleting document: ', error)
    setSnackbar({ visible: true, severity: 'error', message: 'Error deleting document' })
  } else {
    setSnackbar({ visible: true, severity: 'success', message: 'Document deleted successfully' })
  }
}

/**
 * A function to validate email addresses without using Regex.
 * It's a simple function that checks if the email contains invalid characters, spaces, and if it has the correct structure.
 * It tries to complain with all necessary and common RFC rules, without the obscurity of a 500+ chars Regex,
 * also, it's easier to read, understand and modify.
 *
 * Performance-wise, it's not as fast as Regex, but it's fast enough for most use cases thanks to browsers' JS engines optimizations.
 */
export const validateEmail = (email: string): { message?: string; valid: boolean } => {
  if (!email || email.length === 0)
    return {
      valid: false,
      message: 'Email cannot be empty.'
    }

  const allowedChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const allowedSymbols = "!#$%&'*+-/=?^_`{|}~@."
  const allowedCharset: string = allowedChars + allowedSymbols
  const whitespaceChars: string[] = [' ', '\t', '\n']

  // Check if the email contain spaces
  if (whitespaceChars.some((char: string) => email.includes(char)))
    return {
      valid: false,
      message: 'Email cannot contain spaces, tabs or new lines.'
    }

  // Check if all chars are allowed
  if (email.split('').some((char: string) => !allowedCharset.includes(char)))
    return {
      valid: false,
      message: 'Email contains invalid characters.'
    }

  // Slice the email into two parts
  const emailSlices: string[] = email.split('@')

  // Check if there is only one @
  if (emailSlices.length !== 2)
    return {
      valid: false,
      message: 'Email must contain exactly one @.'
    }

  // Check if there is at least 1 dot after the @
  if (emailSlices[1].split('.').length < 2)
    return {
      valid: false,
      message: 'Email must contain at least one dot after the @.'
    }

  // Check if there is at least 1 char before the @
  if (emailSlices[0].length < 1)
    return {
      valid: false,
      message: 'Email must contain at least one character before the @.'
    }

  // Check if the first or last char is not a symbol
  if (!allowedChars.includes(emailSlices[0][0]) || !allowedChars.includes(emailSlices[0][emailSlices[0].length - 1]))
    return {
      valid: false,
      message: 'Email cannot start or end with a symbol.'
    }

  // Check if the email contains two dots in a row (This is to cover cases like 'john..doe@something.com' [RFC-952 with no RFC-1123 double quotes validation])
  if (email.includes('..'))
    return {
      valid: false,
      message: 'Email cannot contain two dots in a row.'
    }

  // Check if second part contains symbols aside ".", "_" and "-"
  if (emailSlices[1].split('').some((char: string) => "!#$%&'*+/=?^`{|}~@".includes(char)))
    return {
      valid: false,
      message: 'Email contains invalid characters in the domain part.'
    }

  // Check if second part ends with an allowed symbol
  if ('._-'.includes(emailSlices[1].charAt(emailSlices[1].length - 1)))
    return {
      valid: false,
      message: 'Email domain cannot end with a symbol.'
    }

  // Check if second part starts with an allowed symbol
  if ('._-'.includes(emailSlices[1].charAt(0)))
    return {
      valid: false,
      message: 'Email domain cannot start with a symbol.'
    }

  // Check if there's always 1 char after a dot
  if (emailSlices[1].split('.').some((part: string) => part.length < 1))
    return {
      valid: false,
      message: 'Email domain must contain at least one character after a dot.'
    }

  // If all checks pass, return valid and no message
  return {
    valid: true
  }
}

/**
 * Converts a number to its written dollar and cents format as would be written on a check.
 * @param {number} num - The number to be converted.
 * @returns {string} - The written format of the number.
 */
export const convertNumberToWrittenFormat = (num: number): string => {
  if (typeof num !== 'number' || num < 0) return 'Invalid Value'
  const belowTwenty = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen'
  ]
  const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  const thousands = ['', 'Thousand', 'Million', 'Billion']

  function convertHundreds(n: number): string {
    let result = ''
    if (n >= 100) {
      result += belowTwenty[Math.floor(n / 100)] + ' Hundred '
      n %= 100
    }
    if (n >= 20) {
      result += tens[Math.floor(n / 10)] + ' '
      n %= 10
    }
    if (n > 0) {
      result += belowTwenty[n] + ' '
    }
    return result.trim()
  }

  function convertToWords(n: number) {
    if (n === 0) return 'Zero'
    let result = ''
    let thousandIndex = 0
    while (n > 0) {
      if (n % 1000 !== 0) {
        result = convertHundreds(n % 1000) + ' ' + thousands[thousandIndex] + ' ' + result
      }
      n = Math.floor(n / 1000)
      thousandIndex++
    }
    return result.trim()
  }

  function convertDecimals(decimal: number): string {
    if (decimal === 0) return 'Zero Cents'
    const cents = Math.round(decimal * 100)
    return `${cents}/100`
  }

  const wholePart = Math.floor(num)
  const decimalPart = num - wholePart

  const words = convertToWords(wholePart)
  const decimals = convertDecimals(decimalPart)

  return `${words} Dollars and ${decimals}`
}

/**
 * This function checks if a value is actually an object.
 *
 * @param obj - The value to be checked
 */
export const isObject = (obj: unknown) => {
  return typeof obj === 'object' && obj instanceof Object && !Array.isArray(obj) && obj.constructor !== Date
}

/**
 * This function handles setting up partial updates to an object in MongoDB.
 *
 * @param obj - The object you want to update
 * @param prefix - The prefix for building the string notation update.
 * (default to empty string since it is used for the recursion updates)
 *
 * @example
 * Input: { data: { firstName: 'test' } }
 * Output: { 'data.firstName': 'test' }
 */
export function buildUpdateObject<T extends Record<string, any>>(obj: T, prefix = ''): Record<string, any> {
  return Object.entries(obj).reduce<Record<string, any>>((acc, [key, value]) => {
    const path = prefix ? `${prefix}.${key}` : key // Build the dot notation path
    if (value && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date)) {
      // Recursively reduce nested objects
      const nestedUpdate = buildUpdateObject(value, path)
      return { ...acc, ...nestedUpdate }
    } else {
      // Add current field to the accumulator
      return { ...acc, [path]: value }
    }
  }, {})
}

/**
 * Creates a URL with the given base URL and optional search parameters.
 *
 * @param url - The base URL to which the search parameters will be appended.
 * @param searchParams - An optional object containing key-value pairs to be used as search parameters.
 * @returns The complete URL with the search parameters appended.
 */
export const createURL = (url: string, searchParams?: Record<string, string>): string => {
  const urlObj = new URL(url)
  urlObj.search = new URLSearchParams(searchParams).toString()
  return urlObj.toString()
}

/**
 * Retrieves the value from an object using a dot-separated key path.
 *
 * @param {object} obj - The object from which to retrieve the value.
 * @param {string} keyPath - The dot-separated key path string.
 * @returns {*} - The value at the specified key path, or false if any segment of the key path does not exist.
 *
 * @example
 * Input: ({this: { that: 'testing'}}, 'this.that')
 * Output: 'testing'
 */
export const getFieldValueFromPath = (obj: object, keyPath: string) => {
  const segments = keyPath.split('.')
  let current = obj
  for (const segment of segments) {
    if (segment in current) {
      // @ts-expect-error TS7053: Element implicitly has an any type because expression of type string can't be used to index type {}
      current = current[segment]
    } else {
      return false
    }
  }
  return current
}

/**
 * Checks if a value is either a string or a number.
 *
 * This function takes an unknown value and determines if it is of type
 * 'string' or 'number'. It returns true if the value is a string or
 * number, otherwise, it returns false.
 *
 * @param value - The value to be checked.
 * @returns A boolean indicating whether the value is a string or a number.
 */
export const isStringOrNumber = (value: unknown): value is number | string => {
  return ['string', 'number'].includes(typeof value)
}
