import { useState, useEffect } from 'reactn'
// MUI Core
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'
import notificationStyles from '../../src/styles/notifications.module.css'
import { makeCSV } from '../../src/helpers/csvHelper'
import { consoleError } from '../../src/helpers/helper'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

// helpers, hooks, dictionaries
import useSWR from 'swr'
import { apiVercelFallback } from '@src/helpers/clientSide/fetch'
import { notificationPriorityTitles } from '../../src/dictionaries/dictionaries'
import { getAllUsers } from '../../src/helpers/userHelper'
import { returnValueAsDate } from '../../src/helpers/newHelper'

// types
import type { NotificationRecipient, UserFromToken, Notification, User } from '@src/dictionaries/commonInterfaces'

interface NotificationData {
  metaData: {
    page: number
    pageSize: number
  }
  rows: Notification[]
}

interface ViewNotificationDialogProps {
  accessLocation?: string
  cellClass?: string
  fetchNotifications?: boolean
  notification?: Notification | null
  open?: boolean
  queryUserNotifications?: () => void
  setNotification?: (notification: Notification | null) => void
  showSnackbar?: (message: string, severity: 'error' | 'info' | 'success' | 'warning', duration?: number) => void
  user: UserFromToken
}

interface DownloadCSV {
  file: Blob
  fileName: string
}

const thisFile = 'components/Common/Notifications/ViewNotificationDialog.js ' // eslint-disable-line no-unused-vars

const ViewNotificationDialog = (props: ViewNotificationDialogProps) => {
  const {
    notification = null,
    cellClass = null,
    queryUserNotifications = () => null,
    user,
    showSnackbar = () => {},
    fetchNotifications = false,
    accessLocation = null,
    setNotification = () => null
  } = props

  // state
  const [viewNotification, setViewNotification] = useState<Notification | null>(notification || null) // the pop up notification
  const [disableClose, setDisableClose] = useState<boolean>(false)
  const [disableAcknowledged, setDisableAcknowledged] = useState<boolean>(false)
  const [disableDone, setDisableDone] = useState<boolean>(false)
  const [doneClicked, setDoneClicked] = useState<boolean>(false)
  const [disableSnooze, setDisableSnooze] = useState<boolean>(false)
  const [addSnooze, setAddSnooze] = useState<boolean>(false)
  const [snoozeDate, setSnoozeDate] = useState<Date>(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
    notification?.recipients?.find((recipient) => recipient?.userId === user?.user_id)?.snooze || null
  )
  // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Blob'.
  const [downloadCSV, setDownloadCSV] = useState<DownloadCSV>({ file: null, fileName: 'loading' })
  const [processingNotification, setProcessingNotification] = useState<boolean>(false)
  /**
   * Displays notification if high priority notification exists for the user.
   */
  const { isLoading: notificationsLoading } = useSWR<NotificationData | null>(
    user && fetchNotifications ? `rest/notifications?userId=${user?.user_id}&isWidget=true` : null,
    apiVercelFallback,
    {
      refreshInterval: 5000,
      revalidateOnFocus: true,
      shouldRetryOnError: true,
      fallbackData: null,
      onSuccess: (data) => {
        if (data?.rows?.length) {
          // filter out notifications that have been acknowledged or done so they don't show up again
          // TODO: this should be done on the api side
          const fsdNotification = data?.rows?.find(
            (notification: Notification) =>
              notification?.priority === 1 &&
              !notification?.recipients?.find((recipient: NotificationRecipient) => recipient?.userId === user?.user_id)
                ?.acknowledged &&
              !notification.recipients?.find((recipient: NotificationRecipient) => recipient?.userId === user?.user_id)
                ?.done
          )
          const headerNotification = data?.rows?.find((notification: Notification) => {
            const isPriority2 = notification?.priority === 2
            const isNotAcknowledged = !notification?.recipients?.find(
              (recipient: NotificationRecipient) => recipient?.userId === user?.user_id
            )?.acknowledged
            const isNotDone = !notification?.recipients?.find(
              (recipient: NotificationRecipient) => recipient?.userId === user?.user_id
            )?.done
            const isNotSnoozed =
              !notification?.recipients?.find((recipient: NotificationRecipient) => recipient?.userId === user?.user_id)
                ?.snooze ||
              new Date(
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                notification?.recipients?.find(
                  (recipient: NotificationRecipient) => recipient?.userId === user?.user_id
                )?.snooze
              ) < new Date()
            return isPriority2 && isNotAcknowledged && isNotDone && isNotSnoozed
          })
          if (fsdNotification) {
            setViewNotification(fsdNotification)
          } else if (headerNotification && accessLocation !== 'page:phone' && accessLocation !== 'page:oppFSD') {
            setViewNotification(headerNotification)
          }
        }
      },
      onError: (error) => console.error('getNotifications error: ', error)
    }
  )
  /**
   * Calls notification api to update the notification with the users acknowledgement
   */
  const acknowledgeNotification = async () => {
    setProcessingNotification(true)
    try {
      const updatedRecipients = viewNotification?.recipients?.map(
        (recipient: Pick<NotificationRecipient, 'acknowledged' | 'userId'>) => {
          if (recipient?.userId === user?.user_id) {
            recipient.acknowledged = true
          }
          return recipient
        }
      )
      const updatedNotification = {
        ...viewNotification,
        recipients: updatedRecipients
      }
      await apiVercelFallback(`rest/notifications/${viewNotification?.id}`, {
        method: 'PATCH',
        body: JSON.stringify(updatedNotification)
      })
      setViewNotification(null)
      setNotification(null)
      queryUserNotifications()
      showSnackbar('Notification acknowledged', 'success')
      setProcessingNotification(false)
    } catch (error) {
      consoleError(thisFile, ' acknowledgeNotification error: ', error)
      setProcessingNotification(false)
    }
  }

  /**
   * Calls notification api to update the notification with the users acknowledgement, done, and snooze date
   */
  const doneNotification = async () => {
    setProcessingNotification(true)
    try {
      const updatedRecipients = viewNotification?.recipients?.map((recipient: NotificationRecipient) => {
        // if the user is the recipient, setting all the values to true
        if (recipient?.userId === user?.user_id) {
          recipient.acknowledged = true
          recipient.done = true
          recipient.snooze = null
          recipient.acknowledged = true
        }
        return recipient
      })
      const updatedNotification = {
        ...viewNotification,
        recipients: updatedRecipients
      }
      await apiVercelFallback(`rest/notifications/${viewNotification?.id}`, {
        method: 'PATCH',
        body: JSON.stringify(updatedNotification)
      })
      setViewNotification(null)
      setNotification(null)
      queryUserNotifications()
      showSnackbar('Notification done', 'success')
      setProcessingNotification(false)
    } catch (error) {
      consoleError(thisFile, ' doneNotification error: ', error)
      setProcessingNotification(false)
    }
  }

  /**
   * Calls the api to update the notification with the users snooze date
   */

  const snoozeNotification = async () => {
    setProcessingNotification(true)
    try {
      const updatedRecipients = viewNotification?.recipients?.map(
        (recipient: Pick<NotificationRecipient, 'snooze' | 'userId'>) => {
          if (recipient?.userId === user?.user_id) {
            recipient.snooze = snoozeDate
          }
          return recipient
        }
      )
      const updatedNotification = {
        ...viewNotification,
        recipients: updatedRecipients
      }
      await apiVercelFallback(`rest/notifications/${viewNotification?.id}`, {
        method: 'PATCH',
        body: JSON.stringify(updatedNotification)
      })
      setViewNotification(null)
      setNotification(null)
      queryUserNotifications()
      showSnackbar('Notification snoozed', 'success')
      setProcessingNotification(false)
    } catch (error) {
      consoleError(thisFile, ' snoozeNotification error: ', error)
      setProcessingNotification(false)
    }
  }
  /**
   * returns the title for the notification based on the priority
   * @param {Number | string} priority
   * @returns {String} title for the notification
   * @example notificationTitle(1)
   */
  const getNotificationTitle = (priority: number | string): string => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return notificationPriorityTitles[priority]
  }

  /**
   * returns a class name based on the current priority
   * @param {Number} priority
   * @returns {String} className
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'priority' implicitly has an 'any' type.
  const getPriorityClassName = (priority) => {
    switch (priority) {
      case 1:
        return notificationStyles.priority_1
      case 2:
        return notificationStyles.priority_2
      case 3:
        return notificationStyles.priority_3
      case 4:
        return notificationStyles.priority_4
      default:
        break
    }
  }

  /**
   * takes a userId and returns the readable name
   * @param {String} userId
   * @returns {String} user name
   */
  const getRecipientName = (userId: string) => {
    const name = !usersLoading && users?.find((user: User) => user?.user_id === userId)?.name
    return name || userId
  }

  /**
   * IF notification is priority 1 and not acknowledged closing the notification is not an option
   * IF notification is already acknowledged, user can not re-acknowledge to prevent useless db calls
   */
  useEffect(() => {
    setDisableClose(
      (viewNotification?.priority === 1 &&
        !viewNotification?.recipients?.find((recipient: NotificationRecipient) => recipient?.userId === user?.user_id)
          ?.acknowledged) ||
        processingNotification
    )
    setDisableAcknowledged(
      viewNotification?.recipients?.find((recipient: NotificationRecipient) => recipient?.userId === user?.user_id)
        ?.acknowledged || processingNotification
    )
    setDisableDone(
      viewNotification?.recipients?.find((recipient: NotificationRecipient) => recipient?.userId === user?.user_id)
        ?.done || processingNotification
    )
    setDisableSnooze(
      viewNotification?.priority === 1 ||
        viewNotification?.recipients?.find((recipient: NotificationRecipient) => recipient?.userId === user?.user_id)
          ?.done ||
        processingNotification
    )
  }, [user?.user_id, viewNotification, processingNotification])

  // @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
  const convertJsonLinkToCsv = async (url) => {
    try {
      let splitUrl = url.split('/')
      splitUrl = splitUrl[splitUrl?.length - 1]?.split('?')?.[0]
      splitUrl = splitUrl?.replace('.json', '')
      if (splitUrl?.length === 13) {
        splitUrl = '' // no title just unix timestamp
      }

      const response = await fetch(url)
      const json = await response?.json()
      // if questions and answers are included convert the array to root columns
      if (json?.[0]?.QandA) {
        for (const row of json) {
          for (const qa of row?.QandA) {
            if (qa?.Q) {
              row[qa.Q] = qa?.A
            }
          }
          delete row?.QandA
        }
      }
      if (json?.[0]?.CaseQuestions) {
        for (const row of json) {
          for (const caseQuestion of row.CaseQuestions) {
            row[caseQuestion.Q] = caseQuestion.A
          }
          delete row.CaseQuestions
        }
      }
      if (json?.[0]?.disqualification?.additionalInfo) {
        for (const row of json) {
          row.additionalInfo = row?.disqualification?.additionalInfo
        }
      }
      if (json?.[0]?.disqualification?.slug) {
        for (const row of json) {
          row.disqualification = row?.disqualification?.label
        }
      }
      // remove _id and campaignId as to not expose identifiers
      for (const row of json) {
        delete row._id
        delete row.campaignId
      }
      const csv = makeCSV(json)
      if (csv) {
        // make timestamp for file name
        const date = Date.now()
        // set state for download link
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' }) // eslint-disable-line
        setDownloadCSV({
          file: blob,
          fileName: splitUrl ? splitUrl + '.csv' : 'Opportunities_' + date + '.csv'
        })
      }
    } catch (error) {
      consoleError(thisFile, ' error: ', error)
    }
  }

  useEffect(() => {
    if (!notificationsLoading && viewNotification && viewNotification?.linkType === 'download') {
      convertJsonLinkToCsv(viewNotification?.link)
    }
  }, [viewNotification])

  // Get a list of all users on initial render
  const [users, setUsers] = useState<User[]>([])
  const [usersLoading, setUsersLoading] = useState(false)
  useEffect(() => {
    if (!user) return
    setUsersLoading(true)
    getAllUsers(setUsers, setUsersLoading)
  }, [])

  return (
    !notificationsLoading &&
    viewNotification && (
      <Box>
        <Dialog id='notificationDialog' open={!!viewNotification?.id} fullWidth>
          <DialogTitle
            className={cellClass || getPriorityClassName(viewNotification?.priority)}
            sx={{ color: 'black' }}
          >
            {getNotificationTitle(viewNotification?.priority)}
          </DialogTitle>
          <DialogContent>
            {processingNotification && (
              <Box
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  color: 'black',
                  background: 'rgba(230, 230, 230, 0.9)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1 // showing this over the dialog
                }}
              >
                <CircularProgress color='primary' />
                <Typography variant='h5' sx={{ paddingTop: 3 }}>
                  Updating Notification
                </Typography>
              </Box>
            )}
            <Grid container spacing={2} sx={{ paddingTop: 2, justifyContent: 'space-between' }}>
              <Grid item xs={12} display={'flex'} flexDirection={'column'}>
                <InputLabel>Sent On:</InputLabel>
                <Typography id='sent-date'>{new Date(viewNotification?.postDate)?.toLocaleString()}</Typography>
              </Grid>
              <Grid item xs={12} display={'flex'} flexDirection={'column'}>
                <InputLabel>From:</InputLabel>
                {usersLoading && (
                  <Box display={'flex'}>
                    <Typography>Loading User...</Typography>
                    <CircularProgress size={20} />{' '}
                  </Box>
                )}
                {!usersLoading && <Typography>{getRecipientName(viewNotification?.sendingUserId)}</Typography>}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Message:</InputLabel>
                <Typography style={{ wordWrap: 'break-word' }}>{viewNotification?.message}</Typography>
              </Grid>
              {viewNotification?.link && (
                <Grid item xs={12}>
                  <InputLabel>Link:</InputLabel>
                  {viewNotification?.linkType === 'download' && downloadCSV?.file && (
                    <a
                      href={window.URL.createObjectURL(downloadCSV?.file)}
                      download={downloadCSV?.fileName}
                      style={{ color: '#1e88e5' }}
                    >
                      {downloadCSV.fileName}
                    </a>
                  )}
                  {viewNotification?.linkType === 'download' &&
                    !downloadCSV?.file &&
                    downloadCSV?.fileName !== 'loading' && <Typography>-Link Expired-</Typography>}
                  {viewNotification?.linkType === 'download' &&
                    !downloadCSV?.file &&
                    downloadCSV?.fileName === 'loading' && <Typography>{downloadCSV.fileName}</Typography>}
                  {viewNotification?.linkType !== 'download' && (
                    <Link href={viewNotification?.link} underline='hover'>
                      {viewNotification?.link}
                    </Link>
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              aria-label='Close'
              disabled={disableClose}
              color='primary'
              onClick={() => {
                setViewNotification(null)
                setNotification(null)
              }}
              sx={{
                display: notification && !doneClicked ? 'block !important' : 'none !important' // don't show the close button if it is a pop up notification, this will prevent the pop up from re-opening
              }}
            >
              Close
            </Button>
            <Button
              aria-label='Snooze'
              disabled={disableSnooze}
              color='primary'
              sx={{
                display: !doneClicked ? 'block !important' : 'none !important' // don't show the close button if it is a pop up notification, this will prevent the pop up from re-opening
              }}
              onClick={() => setAddSnooze(true) /* snoozeNotification(new Date('2022-03-17')) */}
            >
              Snooze
            </Button>
            <Button
              aria-label='Acknowledge'
              color='primary'
              disabled={disableAcknowledged}
              sx={{
                display: !doneClicked ? 'block !important' : 'none !important' // don't show the close button if it is a pop up notification, this will prevent the pop up from re-opening
              }}
              onClick={() => {
                acknowledgeNotification()
              }}
            >
              Acknowledge
            </Button>
            {!doneClicked && (
              <Button
                aria-label='Done'
                color='primary'
                disabled={disableDone}
                onClick={() => {
                  setDoneClicked(true)
                }}
              >
                Done
              </Button>
            )}
            {doneClicked && (
              <DialogActions>
                <Typography>Set notification as done?</Typography>
                <Button
                  aria-label='Cancel'
                  color='primary'
                  onClick={() => {
                    setDoneClicked(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  aria-label='Confirm Done'
                  color='primary'
                  variant='contained'
                  disabled={processingNotification}
                  onClick={() => {
                    doneNotification()
                  }}
                >
                  Confirm
                </Button>
              </DialogActions>
            )}
          </DialogActions>
        </Dialog>
        {addSnooze && (
          <Dialog open={addSnooze}>
            <DialogTitle>Snooze Date</DialogTitle>
            <DialogContent sx={{ py: 2 }}>
              <DesktopDateTimePicker
                value={snoozeDate > new Date() ? returnValueAsDate(snoozeDate) : new Date()}
                onChange={(e) => {
                  // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
                  setSnoozeDate(e)
                }}
                disablePast
              />
            </DialogContent>
            <DialogActions>
              <Button
                aria-label='Cancel'
                disabled={disableClose}
                color='primary'
                onClick={() => {
                  setAddSnooze(false)
                }}
              >
                Cancel
              </Button>
              <Button
                aria-label='Set Snooze Date'
                disabled={disableClose}
                color='primary'
                onClick={() => {
                  setAddSnooze(false)
                  snoozeNotification()
                }}
              >
                Set Snooze Date
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    )
  )
}

export default ViewNotificationDialog
