import Skeleton, { type SkeletonProps } from '@mui/material/Skeleton'

interface SkeletonTextProps extends SkeletonProps {
  fontSize?: number
  text?: string
}

const SkeletonText: React.FC<SkeletonTextProps> = ({
  text = 'Loading...',
  fontSize = 20,
  ...rest // Enable all MUI skeleton props
}) => {
  return (
    <Skeleton
      variant='rectangular'
      width={'100%'}
      height={'100%'}
      animation='wave'
      {...rest}
      style={{
        borderRadius: '5px',
        margin: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'darkgrey',
        fontSize: `${fontSize}px`,
        ...rest?.style
      }}
    >
      {text}
    </Skeleton>
  )
}

export default SkeletonText
