/* eslint-disable react/display-name */
import { useEffect, useState } from 'react'

// MUI Core
import Fab from '@mui/material/Fab'
import AddAlert from '@mui/icons-material/AddAlert'
import Visibility from '@mui/icons-material/Visibility'
import Alert, { type AlertColor } from '@mui/material/Alert'
import {
  GridToolbar,
  DataGridPremium,
  GridActionsCellItem,
  type GridSortModel,
  type GridFilterModel,
  useGridApiRef
} from '@mui/x-data-grid-premium'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'

// components
import CreateEditNotificationDialog from '../../components/Notifications/CreateEditNotificationDialog'
import ViewNotificationDialog from '../../components/Notifications/ViewNotificationDialog'
import CustomPagination from '@components/newOpportunities/GridComponents/CustomPagination'

// hooks, helpers, dictionaries
import useSWR from 'swr'
import { apiVercelFallback } from '@src/helpers/clientSide/fetch'
import { getAllUsers } from '../../src/helpers/userHelper'

// styles
import notificationStyles from '../../src/styles/notifications.module.css'

// types
import type { Notification, UserFromToken, User } from '../../src/dictionaries/commonInterfaces'

interface UserNotificationsProps {
  isWidget?: boolean
  myProfile?: boolean
  user: UserFromToken
}

interface NotificationsData {
  metadata: {
    page: number
    total: number
  }
  rows: Notification[]
}

const thisFile = 'components/Notifications/userNotifications.js ' // eslint-disable-line no-unused-vars

const UserNotifications = (props: UserNotificationsProps) => {
  const { user, isWidget = false, myProfile = false } = props

  const apiRef = useGridApiRef()
  // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [notification, setNotification] = useState<Notification | undefined>(null)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [createEditNotification, setCreateEditNotification] = useState<Notification | undefined>(null)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<AlertColor>('success')
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState<number>(6000)
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(25)
  const [filterQuery, setFilterQuery] = useState<string>('')
  const [sortQuery, setSortQuery] = useState<string>('')

  const defaultSortModel: GridSortModel = [
    {
      field: 'acknowledged',
      sort: 'asc'
    },
    {
      field: 'priority',
      sort: 'asc'
    },
    {
      field: 'postDate',
      sort: 'desc'
    }
  ]
  const sortModel: GridSortModel = defaultSortModel
  const defaultFilterModel: GridFilterModel = {
    items: [
      {
        id: 1,
        field: 'done',
        operator: 'is',
        value: 'False'
      },
      {
        id: 2,
        field: 'snooze',
        operator: 'onOrBefore',
        value: new Date().toISOString()
      }
    ]
  }
  const [filterModel, setFilterModel] = useState<GridFilterModel>(defaultFilterModel)

  // SWR hook to fetch user notifications
  const {
    data: notifications,
    error: notificationsError,
    isLoading: notificationsLoading,
    mutate: refetchNotifications
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  } = useSWR<NotificationsData>(
    `rest/notifications?userId=${user.user_id}&page=${currentPage || 0}&pageSize=${pageSize || 0}&sort=${sortQuery || []}&filter=${filterQuery || ''}${isWidget ? '&isWidget=true' : ''}${myProfile ? '&myProfile=true' : ''}
  `,
    apiVercelFallback,
    {
      refreshInterval: 30000,
      keepPreviousData: true,
      onError: (error) => {
        console.error(`${thisFile} useSWR fetchNotifications error: ${error}`)
      }
    }
  )

  /**
   * Handles page change and fires opp search
   * @param page page number
   */
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  /**
   * handles page size change and fires opp search
   * @param newPageSize page size
   */

  // @ts-expect-error TS(7006) FIXME: Parameter 'newPageSize' implicitly has an 'any' ty... Remove this comment to see the full error message
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize)
    refetchNotifications()
  }

  /**
   * handles the sort model change and fires notification search
   * @param newSortModel
   */
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortQuery(JSON.stringify(newSortModel))
  }

  /**
   * sets new filter model
   */
  const handleFilterModelChange = (newFilterModel: GridFilterModel) => {
    let filterRestrictions = false
    if (newFilterModel?.items?.length) {
      for (const filter of newFilterModel.items as Array<{ type: string }> & GridFilterModel['items']) {
        if (!filter.value && !['isEmpty', 'isNotEmpty'].includes(filter.operator)) {
          filterRestrictions = true
        } else if (!filter.value) {
          filterRestrictions = true
        }
        const type = apiRef.current.getColumn(filter.field)?.type
        // @ts-expect-error TS(2322) FIXME: Type 'GridColType | undefined' is not assignable t... Remove this comment to see the full error message
        filter.type = type
      }
    }
    setFilterModel(newFilterModel.items.length ? newFilterModel : { items: [] })
    if (!filterRestrictions) {
      setFilterQuery(JSON.stringify(newFilterModel))
      refetchNotifications()
    }
  }

  /**
   * Shows a snackbar with a message and variant to the user
   * @param message snackbar message
   * @param variant color of snackbar
   * @param duration time to show snackbar
   */
  const showSnackbar = (message: string, variant: AlertColor, duration = 6000) => {
    // Dynamic snackbar
    setSnackbarMessage(message)
    setSnackbarVariant(variant)
    setSnackbarAutoHideDuration(duration)
    setSnackbarOpen(true)
  }

  // Get a list of all users on initial render
  const [users, setUsers] = useState<User[]>([])
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  useEffect(() => {
    if (!user) return
    setUsersLoading(true)
    getAllUsers(setUsers, setUsersLoading)
  }, [])

  /**
   * returns a class name based on the current priority
   * @param {Number} priority
   * @returns {String} className
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'priority' implicitly has an 'any' type.
  const getPriorityClassName = (priority) => {
    switch (priority) {
      case 1:
        return notificationStyles.priority_1
      case 2:
        return notificationStyles.priority_2
      case 3:
        return notificationStyles.priority_3
      case 4:
        return notificationStyles.priority_4
      default:
        break
    }
  }

  /**
   * takes a userId and returns the readable name
   * @param {String} userId
   * @returns {String} user name
   */
  const getRecipientName = (userId: string) => {
    const name = users?.find((user: User) => user.user_id === userId)?.name
    return name || userId
  }

  /**
   * toggles the modal
   */
  const toggleModal = () => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setCreateEditNotification(null)
  }

  // column options for the notification grid
  const columnOptions = {
    filterable: true,
    sortable: true,
    resizable: true
  }

  const notificationGridColumns = [
    {
      field: 'id',
      headerName: 'id',
      width: 50,
      filterable: false,
      hide: true
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 50,
      type: 'actions',
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      getActions: (params) => [
        <GridActionsCellItem
          key={'view' + params?.row?.id}
          label='View'
          aria-label={'View Notification'}
          icon={<Visibility />}
          onClick={() => setNotification(params?.row)}
        />
      ]
    },
    {
      field: 'postDate', // using postDate for time notification was sent to user
      headerName: 'Created',
      flex: 1,
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      valueGetter: (params) => new Date(params?.value),
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      valueFormatter: (params) => {
        return new Date(params?.value)?.toLocaleString()
      },
      type: 'dateTime',
      ...columnOptions
    },
    {
      field: 'priority',
      headerName: 'Priority',
      width: 130,
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      cellClassName: (params) => getPriorityClassName(params?.value),
      type: 'number',
      ...columnOptions
    },
    {
      field: 'recipients',
      headerName: 'Acknowledged',
      flex: 1,
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      cellClassName: (params) => {
        // @ts-expect-error TS(7006) FIXME: Parameter 'recipient' implicitly has an 'any' type... Remove this comment to see the full error message
        return params.row?.recipients?.find((recipient) => recipient?.userId === user.user_id)?.acknowledged
          ? notificationStyles.acknowledged
          : notificationStyles.pending
      },
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      valueGetter: (params) => {
        // @ts-expect-error TS(7006) FIXME: Parameter 'recipient' implicitly has an 'any' type... Remove this comment to see the full error message
        return params.row?.recipients?.find((recipient) => recipient?.userId === user.user_id)?.acknowledged
          ? 'True'
          : 'False'
      },
      type: 'singleSelect',
      valueOptions: ['True', 'False'],
      ...columnOptions
    },
    {
      field: 'sendingUserId',
      headerName: 'From',
      flex: 1,
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      valueGetter: (params) => getRecipientName(params?.value),
      ...columnOptions
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 2,
      ...columnOptions
    },
    {
      field: 'done',
      headerName: 'Done',
      flex: 1,
      type: 'singleSelect',
      valueOptions: ['True', 'False'],
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      valueGetter: (params) => {
        // @ts-expect-error TS(7006) FIXME: Parameter 'recipient' implicitly has an 'any' type... Remove this comment to see the full error message
        return params.row?.recipients?.find((recipient) => recipient?.userId === user.user_id)?.done ? 'True' : 'False'
      },
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      cellClassName: (params) =>
        // @ts-expect-error TS(7006) FIXME: Parameter 'recipient' implicitly has an 'any' type... Remove this comment to see the full error message
        params.row?.recipients?.find((recipient) => recipient?.userId === user.user_id)?.done
          ? notificationStyles.acknowledged
          : notificationStyles.pending,
      ...columnOptions
    },
    {
      field: 'snooze',
      headerName: 'Snoozed',
      flex: 2,
      type: 'dateTime',
      headerClassName: () => notificationStyles.snoozed,
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      valueGetter: (params) => {
        // @ts-expect-error TS(7006) FIXME: Parameter 'recipient' implicitly has an 'any' type... Remove this comment to see the full error message
        return new Date(params.row?.recipients?.find((recipient) => recipient.userId === user.user_id)?.snooze)
      },
      // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
      cellClassName: (params) =>
        // @ts-expect-error TS(7006) FIXME: Parameter 'recipient' implicitly has an 'any' type... Remove this comment to see the full error message
        new Date(params.row?.recipients?.find((recipient) => recipient.userId === user.user_id)?.snooze) > new Date()
          ? notificationStyles.snoozed
          : null,
      ...columnOptions
    }
  ]
  return (
    <Box>
      {usersLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '92vh'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {notificationsError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '92vh'
          }}
        >
          <h3>Error loading notifications, please see console for details</h3>
        </Box>
      )}
      {createEditNotification && (
        <Dialog
          open={true}
          onClose={() => {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            setCreateEditNotification(null)
          }}
        >
          <CreateEditNotificationDialog
            users={users}
            // @ts-expect-error TS(2322) FIXME: Type '(priority: any) => string | undefined' is no... Remove this comment to see the full error message
            cellClass={getPriorityClassName}
            queryNotifications={refetchNotifications}
            user={user}
            toggleModal={toggleModal}
            showSnackbar={showSnackbar}
          />
        </Dialog>
      )}
      {!usersLoading && !notificationsError && (
        <div id='userNotifications' style={{ height: '92vh', width: '100%' }}>
          <Fab
            variant='extended'
            aria-label='add-notification'
            color='secondary'
            style={{ width: '20%', marginTop: '1em', marginBottom: '1em' }}
            onClick={() => {
              setCreateEditNotification({
                // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
                id: null,
                recipients: [
                  {
                    userId: user.user_id,
                    acknowledged: false,
                    done: false,
                    snooze: null
                  }
                ],
                sendingUserId: user.user_id,
                priority: 2,
                message: '',
                postDate: new Date(),
                createdAt: new Date(),
                link: '',
                linkType: '',
                userGroups: [],
                updatedAt: new Date()
              })
            }}
          >
            <AddAlert style={{ marginRight: '0.5em' }} />
            <div className='qa-add-campaign'>Add New Notification</div>
          </Fab>
          <DataGridPremium
            apiRef={apiRef}
            // @ts-expect-error TS(2322) FIXME: Type '({ field: string; headerName: string; width:... Remove this comment to see the full error message
            columns={notificationGridColumns}
            rowCount={notifications?.metadata?.total || 0}
            rows={notifications?.rows || []}
            loading={notificationsLoading}
            rowHeight={38}
            disableMultipleRowSelection
            pagination
            paginationMode='server'
            paginationModel={{ page: currentPage, pageSize }}
            onPaginationModelChange={(newPaginationModel) => {
              handlePageChange(newPaginationModel.page)
              handlePageSizeChange(newPaginationModel.pageSize)
            }}
            sortingMode='server'
            onSortModelChange={(newSortModel) => handleSortModelChange(newSortModel)}
            filterMode='server'
            onFilterModelChange={(newFilterModel) => handleFilterModelChange(newFilterModel)}
            initialState={{
              sorting: {
                sortModel
              },
              columns: {
                columnVisibilityModel: {
                  id: false
                }
              }
            }}
            slots={{
              // @ts-expect-error TS(2322) FIXME: Type 'false | ForwardRefExoticComponent<Omit<GridT... Remove this comment to see the full error message
              toolbar: !isWidget && GridToolbar,
              pagination: CustomPagination,
              loadingOverlay: LinearProgress
            }}
            slotProps={{
              filterPanel: {
                columnsSort: 'asc'
              }
            }}
            disableRowSelectionOnClick
            filterModel={isWidget ? filterModel : undefined}
          />
        </div>
      )}
      {notification && (
        <ViewNotificationDialog
          cellClass={getPriorityClassName(notification.priority)}
          queryUserNotifications={refetchNotifications}
          showSnackbar={showSnackbar}
          user={user}
          notification={notification}
          // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<Notification | undef... Remove this comment to see the full error message
          setNotification={setNotification}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackbarAutoHideDuration}
        onClose={() => {
          setSnackbarOpen(false)
        }}
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false)
          }}
          severity={snackbarVariant}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default UserNotifications
