import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { baseUrl } from '../../src/helpers/helper'
import { type UserFromToken, type SupporticleInterface } from '../../src/dictionaries/commonInterfaces'
import 'react-quill/dist/quill.snow.css'

import Link from '@mui/material/Link'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import EditOutlined from '@mui/icons-material/EditOutlined'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import CopyrightIcon from '@mui/icons-material/Copyright'
import Stack from '@mui/material/Stack'
import { type Theme } from '@mui/material/styles'

import OppDocumentDownload from '../Opportunities/Opportunity/OppDocumentDownload'

interface Props {
  article?: SupporticleInterface
  edit?: boolean
  handleArticleTextChange?: (value: string) => void
  isClientPortal?: boolean
  newTab?: boolean
  panel?: boolean
  setLibraryArticleToEdit?: (libraryArticleToEdit: SupporticleInterface) => void
  theme: Theme
  token?: string
  user: UserFromToken
}

const Supporticle: React.FC<Props> = ({
  article,
  user,
  panel,
  newTab,
  edit,
  theme,
  setLibraryArticleToEdit,
  isClientPortal
}) => {
  const [showInfo, setShowInfo] = useState<boolean>(false)

  const slug = article?.articleTitle?.replaceAll(' ', '_').replaceAll('/', '_')

  const ReactQuill = dynamic(async () => await import('react-quill'), { ssr: false })

  return (
    <Container
      id={article?.articleTitle}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        direction: 'ltr',
        padding: 0,
        maxWidth: '100%',
        marginBottom: '20px'
      }}
    >
      {!edit && user?.permissions.includes('read:codyAI') && article?.codyAI && (
        <Stack style={{ width: '80%' }}>
          <Box
            sx={{
              width: '100%',
              height: 200,
              overflow: 'hidden',
              border: `3px solid ${theme?.palette.primary.dark}`,
              borderRadius: '5px 5px 0 0'
            }}
          >
            <iframe
              src={article?.codyAI}
              name='campaignBotLink'
              height={300}
              width='100%'
              style={{ marginLeft: '-2px', marginTop: '-75px' }}
            />
          </Box>

          <Stack
            direction='row'
            spacing={1}
            mb={2}
            alignItems='center'
            justifyContent='center'
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: theme?.palette.primary.dark,
              borderRadius: '0 0 5px 5px'
            }}
          >
            <Typography style={{ fontSize: '20px', color: theme?.palette.secondary.light }}>CaseBot</Typography>
            <CopyrightIcon style={{ height: 12, width: 12, color: theme?.palette.secondary.light }} />
          </Stack>
        </Stack>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', direction: 'ltr' }}>
        {!newTab && !edit && !isClientPortal && (
          <Tooltip placement='bottom-end' title={'Show details'}>
            <IconButton aria-label='more-info' size='small' onClick={() => setShowInfo(!showInfo)}>
              <InfoOutlined style={{ color: theme?.palette.primary.main }} />
            </IconButton>
          </Tooltip>
        )}

        {/* Edit article */}
        {user?.permissions?.includes('update:supporticle') && !edit && !panel && !newTab && (
          <Tooltip placement='bottom-end' title={'Edit Article'}>
            <IconButton
              aria-label='edit-article'
              size='small'
              onClick={() => {
                // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                setLibraryArticleToEdit(article)
              }}
            >
              <EditOutlined style={{ color: theme?.palette.primary.main, width: '40px' }} />
            </IconButton>
          </Tooltip>
        )}

        {/* Open in new tab */}
        {!newTab && !edit && !isClientPortal && (
          <Tooltip placement='bottom-end' title={'Open in new tab'}>
            <IconButton
              aria-label='open-in-new-tab'
              size='small'
              onClick={() => {
                const url = `${baseUrl}/support/${slug}`
                window.open(url, '_blank')
              }}
            >
              <ArrowOutwardIcon style={{ color: theme?.palette.primary.main }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Typography mb={2} style={{ color: theme?.palette.primary.main }}>
        {article?.articleTitle}
      </Typography>

      {/* Article Details */}
      {showInfo && (
        <Box style={{ marginBottom: '10px', maxWidth: '50%' }}>
          <Typography>
            <b> Title: </b>
            {article?.articleTitle}
          </Typography>
          <Typography>
            <b> Category: </b>
            {article?.type}
          </Typography>
          {!!article?.campaignGroups?.length && (
            <Typography>
              <b> Campaign Groups: </b>
              {article.campaignGroups.toString()}
            </Typography>
          )}
          {article?.createdAt && (
            <Typography>
              <b>Created: </b>
              {new Date(article?.createdAt).toLocaleString()}
              <br />
              &emsp;<b> By: </b>
              {article?.author}
            </Typography>
          )}
          {article?.updatedAt && (
            <Typography>
              <b>Last Updated: </b>
              {new Date(article?.updatedAt).toLocaleString()}
              <br />
              &emsp;<b> By: </b>
              {article?.updatedBy}
            </Typography>
          )}
          {!!article?.userPermissions?.length && (
            <Typography>
              <b> User Restrictions: </b>
              {article?.userPermissions?.join(', ')}
            </Typography>
          )}
        </Box>
      )}

      {/* Links and attachments */}
      {!edit && (!!article?.links?.length || !!article?.attachmentsForContent?.length) && (
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '20px',
            textAlign: 'center'
          }}
        >
          {article?.attachmentsForContent?.map((attachment: any, index: number) => (
            <Box key={index.toString()} style={{ position: 'relative' }}>
              <Chip
                label={attachment.name?.split('/')[3] || attachment.name || ''}
                color={'primary'}
                icon={
                  // @ts-expect-error TS(2322) FIXME: Type '{ fileName: any; fileNameWithPath: any; from... Remove this comment to see the full error message
                  <OppDocumentDownload fileName={attachment?.name} fileNameWithPath={attachment?.name} fromMedicalTab />
                }
              />
            </Box>
          ))}

          {article?.links?.map((link: any, index: number) => (
            <Link
              key={link + index}
              href={link}
              variant='body2'
              underline='hover'
              target='blank'
              sx={{
                color: theme?.palette.primary.main,
                cursor: 'pointer',
                fontSize: '16px',
                marginBottom: '5px'
              }}
            >
              {link}
            </Link>
          ))}
        </Container>
      )}

      {/* Text editor */}
      {article && Object.keys(article).includes('articleText') && (
        <>
          <Container
            style={{
              padding: 0,
              border: `3px solid ${theme?.palette.primary.dark}`,
              borderRadius: '5px',
              textAlign: 'center'
            }}
          >
            <ReactQuill
              modules={{
                toolbar: false,
                clipboard: {
                  matchVisual: false
                },
                history: {
                  userOnly: true
                }
              }}
              style={{ backgroundColor: theme?.palette.common.white, color: 'black' }}
              id='articleText'
              theme='snow'
              value={article?.articleText}
              readOnly
            />
          </Container>
        </>
      )}

      {/* Videos */}
      {!edit &&
        article?.videoLinks?.map((link: string, index: number) => (
          <Box key={link + index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <iframe src={link} width={550} height={320} allowFullScreen style={{ marginBottom: 20 }} />
          </Box>
        ))}
    </Container>
  )
}

export default Supporticle
