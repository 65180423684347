import { useState } from 'react'
// MUI
import Box from '@mui/material/Box'
import Button, { type ButtonOwnProps } from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// MUI Icons
import BugReportIcon from '@mui/icons-material/BugReport'
// Types
import type { UserFromToken } from '@src/dictionaries/commonInterfaces'

interface BugReportDialogProps {
  buttonColor?: ButtonOwnProps['color']
  user: UserFromToken
}

const BugReportDialog = ({ user, buttonColor }: BugReportDialogProps) => {
  const [open, setOpen] = useState(false)

  return (
    <Box>
      <IconButton
        onClick={() => {
          setOpen(true)
        }}
        color={buttonColor || 'default'}
        aria-label='report-bug'
      >
        <BugReportIcon />
      </IconButton>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby='bug-report-dialog-title'
          sx={{
            zIndex: 100000
          }}
        >
          <DialogTitle id='bug-report-dialog-title'>Report a bug</DialogTitle>
          <DialogContent>
            {!user?.email?.includes('@caseopp.com') &&
            !user?.email?.includes('@avalaw.com') &&
            !user?.email?.includes('@reciprocityindustries.com') ? (
              <Typography>Please report the bug to {<b>it@reciprocity.atlassian.net</b>}</Typography>
            ) : (
              <Typography>Please report the bug to the slack channel {<b>#tech-support</b>}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}

export default BugReportDialog
